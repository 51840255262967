import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { API, graphqlOperation, Auth } from 'aws-amplify';
// import { listCampaigns } from 'src/graphql/queries';
import { listCampaigns } from 'src/graphql/queries';
import CardPostDash from 'src/component/publiccomponent/CardPostDash';
// import { listCampaignspublic2 } from 'src/graphql/queries';
import { Link } from 'react-router-dom';


export function AdminsPage(props: any) {
    const [ManagerIDLogged, setManagerIDLogged] = React.useState('');
    const [campaigns, setCampaigns] = React.useState([]);

    async function getCampaigns() {
		const response = await API.graphql({ query: listCampaigns, authMode: 'API_KEY' });
		const { data } = response as { data?: any };

		setCampaigns(data.listCampaigns.items);
	/*
		if (data && data.listCampaigns && data.listCampaigns.items) {
			const managerID = ManagerIDLogged;
			const campaigns = data.listCampaigns.items.filter(campaign => campaign.managerID === managerID);
			setCampaigns(campaigns);
		}*/
	}
	

    React.useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
            .then((user) => {
                setManagerIDLogged(user.signInUserSession.accessToken.payload.username);
            })
            .catch((err) => console.log(err));
    }, []);

    React.useEffect(() => {
        if (ManagerIDLogged) {
            getCampaigns();
        }
    }, [ManagerIDLogged]);
	  


	return (
		<><React.Fragment>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell align='right'>Description</TableCell>
							<TableCell align='right'>Duration</TableCell>
							<TableCell align='right'>Fund Goal</TableCell>
							<TableCell align='right'>Donation Received</TableCell>
							<TableCell align='right'># of Donor</TableCell>
							<TableCell align='right'># of Member</TableCell>
						</TableRow>
					<TableBody>
						{campaigns.length
							? campaigns.map((row: any) => (
								<TableRow
									key={row.id}
									sx={{
										'&:last-child td, &:last-child th': {
											border: 0,
										},
									}}
								>
									<TableCell component='th' scope='row'>
										<Link to={'../viewcampaign/' + row.id}> {row.name}</Link>
									</TableCell>
									<TableCell align='right'>{row.short_description}</TableCell>
									<TableCell align='right'>
										{new Date(row.start_at).toLocaleDateString('en-US') + ' to ' + new Date(row.end_at).toLocaleDateString('en-US')}
									</TableCell>
									<TableCell align='right'>{'$' + row.fund_goal}</TableCell>
									<TableCell align='right'>{row.donation_received}</TableCell>
									<TableCell align='right'>{row.donor_count}</TableCell>
									<TableCell align='right'>{row.donor_count}</TableCell>
								</TableRow>
							))
							: null}
						
					</TableBody>
				</Table>
			</TableContainer>
		</React.Fragment>
		
		<CardPostDash title={"Your Campaigns"} cardinraw={1} posts={campaigns} /></>

	);
}
