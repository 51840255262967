// import * as React from 'react';
// import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Container from '@mui/material/Container';

import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import {
	Paper,
	FormControl,
	TextField,
	Stack,
	Button,
	Typography,
	Box,
	FormLabel,
	RadioGroup,
	Radio,
	FormControlLabel,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { getCampaign } from 'src/graphql/queries';
import { Campaign, Rewardcategory } from 'src/models';
import { API } from 'aws-amplify';
import ReactPlayer from 'react-player';

interface CampaignDescPostProps {
	post: {
		title: string;
		description: string;
		start_at: string;
		end_at: string;
		image: string;
		imageLabel: string;
		link_video: string | null | undefined;
		fund_goal: string | number;
		donation_received: string | number | null | undefined;
		CRewardcategories: { items: (Rewardcategory | null)[] } | null | undefined;
	};
}

export default function DescFeaturedPost(props: CampaignDescPostProps) {
	const navigate = useNavigate();
	let paramdata = useParams();

	const { post } = props;
	let start_date = new Date(post.start_at);
	let end_date = new Date(post.end_at);
	let items: any = [
		{ id: "1", minimum_amount: "10", description: "Donate $10" },
		{ id: "2", minimum_amount: "25", description: "Donate $25" },
		{ id: "3", minimum_amount: "50", description: "Donate $50" },
	];


	const [selectReward, setRewardID] = React.useState('');
	const [total, setTotal] = React.useState('');
	//  console.log(total)
	const submitHandler = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (selectReward != '') {
			navigate('../admin/checkout/' + paramdata.campaignID + '/' + selectReward + '/' + total);
		}
	};
	const validUrl = (str: string) => {
		try {
			new URL(str);
		} catch (_) {
			return false;
		}
		return true;
	};

	// to get amount
	function searchFor(id: string) {
		var results = items;
		// toSearch = trimString(toSearch); // trim it
		for (var i = 0; i < items.length; i++) {
			for (var key in items[i]) {
				if (items[i]['id'] == id) {
					return items[i]['minimum_amount'];
				}
			}
		}
		// return results;
	}

	const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRewardID(event.target.value);
		setTotal(searchFor(event.target.value));
	};

	// Calculate the progress as a percentage
	let progress = 0;
	if (post.donation_received && post.fund_goal) {
		let donation = typeof post.donation_received === 'number' ? post.donation_received : Number(post.donation_received);
		let goal = typeof post.fund_goal === 'number' ? post.fund_goal : Number(post.fund_goal);
		progress = (donation / goal) * 100;
	}


	return (
		<Container>
			<Grid container spacing={1} style={{ padding: '0 0.3%' }}>
				<Grid item xs={12} md={6} style={{ paddingBottom: '2rem' }}>
					<Typography component='h2' variant='h3' align='center' paddingBottom='25px'>
						{post.title}
					</Typography>
					<Typography variant='h5' paragraph paddingBottom='25px'>
						{post.description}
					</Typography>
					<Typography variant='h6' color='text.third' paddingBottom='5px'>
						Campaign Duration : {start_date.toDateString()} to {end_date.toDateString()}
					</Typography>
					<Typography variant='h6' color='text.third' paddingBottom='15px'>
						Funding Goal: $ {post.fund_goal}
					</Typography>
					<Typography variant='h6' color='text.secondary' paddingBottom='25px'>
						Total Fund Received: $ {post.donation_received}
					</Typography>
					<Typography variant='h6' color='text.secondary' paddingBottom='25px'>
						Donation Progress:
					</Typography>
					<Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
						<Box sx={{ width: '100%', mr: 1 }}>
							<LinearProgress variant="determinate" value={progress} sx={{ height: '10px' }} />
						</Box>
						<Box minWidth={35}>
							<Typography variant="body2" color="text.secondary">{`${Math.round(
								progress,
							)}%`}</Typography>
						</Box>
					</Box>

				</Grid>
				<Grid item xs={12} md={6} style={{ paddingTop: '4rem', paddingLeft: '4rem' }}>
					{post?.link_video && validUrl(post.link_video) && (
						<div className="player-wrapper" style={{ position: 'relative', paddingTop: '45%' }}>
							<ReactPlayer
								className='react-player'
								url={post.link_video}
								width='100%'
								height='100%'
								controls={true}
								style={{ position: 'absolute', top: 0, left: 0 }}
							/>
						</div>
					)}
				</Grid>
				<Grid item xs={12}>
					<form onSubmit={submitHandler}>
						<FormControl>
							<FormLabel id='demo-controlled-radio-buttons-group'>
								<Typography variant='h4'> Select your reward</Typography>
							</FormLabel>
							<RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group">
								{items?.map((allreward: any) => (
									<FormControlLabel
										key={allreward.id}
										value={allreward.id}
										control={<Radio onChange={radioHandler} />}
										label={`${allreward.description}`}
									/>
								))}
								<FormControlLabel
									value="custom"
									control={<Radio onChange={radioHandler} />}
									label={
										<Stack direction="row" alignItems="center">
											<TextField
												type="number"
												label="Custom Amount"
												inputProps={{ min: "0", max: "5000", step: "1" }}
												value={total}
												onChange={(e: ChangeEvent<HTMLInputElement>) => setTotal(e.target.value)}
											/>
										</Stack>
									}
								/>
							</RadioGroup>
							<Button type='submit' variant='contained' sx={{ mt: 5, mb: 3 }}>
								Donate
							</Button>
						</FormControl>
					</form>
				</Grid>
			</Grid>
		</Container>

	);

}
