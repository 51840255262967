//FeaturePost picks the campaigns with the most number of donors

import { API, graphqlOperation } from 'aws-amplify';
import React from 'react';
import { listCampaigns } from 'src/graphql/queries';
import CardPost from './CardPost';
import { Campaign } from 'src/models';

function FeaturePost() {
  const cardsPerRow = 2;
  const title = 'Popular Campaigns';

  const [campaigns, setCampaigns] = React.useState<Campaign[]>([]);

  async function getCampaigns() {
    const data: any = await API.graphql({ query: listCampaigns, variables: { limit: 2 }, authMode: 'API_KEY' });

    if (data.data.listCampaigns.items !== undefined) {
      const sortedCampaigns = data.data.listCampaigns.items.sort((a: Campaign, b: Campaign) => {
        if (a.donor_count && b.donor_count) {
          return b.donor_count - a.donor_count;
        }
        if (!a.donor_count) return 1;
        if (!b.donor_count) return -1;
        return 0;
      });

      setCampaigns(sortedCampaigns);
    }
  }

  React.useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <>
      <CardPost title={title} cardinraw={cardsPerRow} posts={campaigns} />
    </>
  );
}

export default FeaturePost;




/* Previous verion of FearturePost.tsx

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

interface FeaturedPostProps {
	post: {
		date: string;
		description: string;
		image: string;
		imageLabel: string;
		title: string;
	};
}

export default function FeaturedPost(props: FeaturedPostProps) {
	const { post } = props;

	return (
			<CardActionArea component='a' href='#' style={{padding: '1.5px', borderRadius: '4px'}}> 
				<Card sx={{ display: 'flex'}}>
					<CardContent sx={{ flex: 1 }}>
						<Typography component='h2' variant='h5'>
							{post.title}
						</Typography>
						<Typography variant='subtitle1' color='text.secondary'>
							{post.date}
						</Typography>
						<Typography variant='subtitle1' paragraph>
							{post.description}
						</Typography>
						{/* <Typography variant='subtitle1' color='primary'>
							Continue reading...
						</Typography>
					</CardContent>
					<CardMedia component='img' sx={{ width: 160, display: { xs: 'none', sm: 'block' } }} image={post.image} alt={post.imageLabel} />
				</Card>
			</CardActionArea>
	);
}

*/
