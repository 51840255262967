import { Avatar } from '@mui/material';
import './profile.css';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import PersonIcon from '@mui/icons-material/Person';
import CakeIcon from '@mui/icons-material/Cake';
import Button from '@mui/material/Button';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useNavigate } from 'react-router';

export default function ProfilePage() {
	const [selected, setSelected] = React.useState(0);

	return (
		<div className='profile-container'>
			<div className='profile-title-row'>
				<div className={selected === 0 ? 'profile-title-on' : 'profile-title'} onClick={() => setSelected(0)}>
					Profile
				</div>
				{/* <div className={selected === 1 ? 'profile-title-on' : 'profile-title'} onClick={() => setSelected(1)}>
					Campaign
				</div>
				<div className={selected === 2 ? 'profile-title-on' : 'profile-title'} onClick={() => setSelected(2)}>
					Contributions
				</div> */}
			</div>
			{selected === 0 && <Profile />}
			{/* {selected === 1 && <CamPaign />}
			{selected === 2 && <ContributionTable />} */}
		</div>
	);
}

const CamPaign = () => {
	return (
		<div>
			<div className='profile-CamPaign-title'>Campaigns I'm On</div>
			<div>
				No campaigns under your belt yet, but you have great ideas and so do the people you know, ou could start a campaign today or team up
				with friends to make your ideas come to life!
			</div>
		</div>
	);
};

const ContributionTable = () => {
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
				<TableHead>
					<TableRow>
						<TableCell>Date</TableCell>
						<TableCell align='right'>Campaign</TableCell>
						<TableCell align='right'>Amount</TableCell>
						<TableCell align='right'>Perk</TableCell>
						<TableCell align='right'>Visibility</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component='th' scope='row'>
							{1}
						</TableCell>
						<TableCell align='right'>test</TableCell>
						<TableCell align='right'>test</TableCell>
						<TableCell align='right'>test</TableCell>
						<TableCell align='right'>test</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const Profile = () => {
	const [showPassword, setShowPassword] = React.useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const submit = () => {
		alert('ddddd');
	};
	return (
		<div className='profile-content-container'>
			<div className='profile-row'>
				{/* <div className='profile-sub-title'>Avatar</div> */}
				<Avatar size='large'>J</Avatar>
			</div>
			<div className='profile-row'>
				<TextField
					id='outlined-required'
					label='Username'
					placeholder='Please enter your username'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<AccountCircle />
							</InputAdornment>
						),
					}}
				/>
			</div>
			<div className='profile-row'>
				<TextField
					label='Email'
					placeholder='Please enter your email'
					type='email'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<EmailIcon />
							</InputAdornment>
						),
					}}
				/>
			</div>
			<div className='profile-row'>
				<TextField
					label='Password'
					placeholder='Please enter your password'
					type={showPassword ? 'text' : 'password'}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<PasswordIcon />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge='end'
								>
									{showPassword ? <Visibility onClick={handleClickShowPassword} /> : <VisibilityOff onClick={handleClickShowPassword} />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</div>
			<div className='profile-row'>
				<TextField
					id='outlined-required'
					label='First Name'
					placeholder='Please enter your first name'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<PersonIcon />
							</InputAdornment>
						),
					}}
				/>
			</div>
			<div className='profile-row'>
				<TextField
					id='outlined-required'
					label='Last Name'
					placeholder='Please enter your last name'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<PersonIcon />
							</InputAdornment>
						),
					}}
				/>
			</div>
			<div className='profile-row'>
				<TextField
					id='outlined-required'
					label='Phone Number'
					placeholder='Please enter your phone number'
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<PhoneAndroidIcon />
							</InputAdornment>
						),
					}}
				/>
			</div>
			<div className='button-container'>
				<Button variant='contained' onClick={submit}>
					Submit
				</Button>
			</div>
		</div>
	);
};
