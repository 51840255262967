import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';
import { GridSize } from '@mui/material/Grid';
import { Campaign } from 'src/models';
import { Link } from 'react-router-dom';

type GRID_SIZES = 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
interface SingleCardProps {
	post: Campaign;
	cardinraw: number;
}

export default function SingleCard(props: SingleCardProps) {
	const mdvalue = 12 / props.cardinraw;
	const post = props.post;

	return (
		<Grid item xs={12} md={Number(mdvalue) as GridSize}>
			<Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', boxShadow: '2px 2px 8px rgba(255, 0, 0, 0.1)' }}>
				<CardActionArea component={Link} to={'/campaign/' + post.id} sx={{ height: '100%', display: 'flex', flexDirection: 'column', textDecoration: 'none' }}>
					<CardMedia
						component='img'
						height='140'
						image={post.link_thumbnail}
						sx={{ objectFit: 'cover', width: '100%' }}
					/>
					<CardContent sx={{ flexGrow: 1 }}>
						<Typography gutterBottom variant='h5' component='div' sx={{ color: '#ff0000' }}>
							{post.name}
						</Typography>
						<Typography variant='body2' color='text.secondary'>
							{post.short_description}
						</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		</Grid>
	);
}
