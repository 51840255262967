import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function AboutContent() {
    const image = require('../../../assets/about_us_image.jpg');
    return (
        <Box
            sx={{
                background: '#fff',
                borderRadius: 0.5,
                padding: '2rem',
                fontSize: 20,
                boxShadow: '0px 4px 8px rgba(38, 38, 38, 0.2)',
                marginBottom: '3rem',
                marginTop: '4rem'
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', padding: '7rem' }}>
                <div style={{ width: '65%', display: 'flex', flexDirection: 'column', justifyContent:'center', margin: '0 auto' }}>
                    <Typography variant='h3' gutterBottom component='div' style={{fontWeight: 'bold'}}>
                        About Us
                    </Typography>
                    <Typography gutterBottom style={{fontSize: '19px', fontWeight: 'bold'}}>
                        FundRacer is a platform that can help all racing drivers realize their dreams. Racing has been the dream of many
                        people for years. However, a racing team or car cannot thrive without significant financial support. Too many
                        outstanding racers have had to give up their dreams due to lack of funding. FundRacer was born to make these dreams a reality.
                        <br />
                        <br />
                        FundRacer provides an online sponsorship platform for racers and sponsors, including: online fundraising, fundraising event
                        management, event fundraising, custom donation pages, and various widgets. FundRacer is at the forefront of functionality,
                        experience, and style, and is committed to creating the best technology in the field of online fundraising for racers and
                        sponsors.
                    </Typography>
                </div>
                <div
                    style={{
                        width: '30%', 
                        display: 'flex',
                        height: 'auto',
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        margin: '0 auto',
                    }}
                ></div>
            </div>
        </Box>
    );
}
