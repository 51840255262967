import { Paper, FormControl, TextField, Stack, Button, CardMedia, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createCampaign, updateCampaign } from 'src/graphql/mutations';
const { Configuration, OpenAIApi } = require("openai");
import React, { ChangeEvent, FormEvent, useEffect } from 'react';
import { Campaign } from 'src/models';
import { useNavigate } from 'react-router-dom';
import config from 'src/aws-exports';
import { v4 as uuidv4 } from 'uuid';
const { aws_user_files_s3_bucket_region: region, aws_user_files_s3_bucket: bucket } = config;
import Lambda from 'aws-sdk/clients/lambda'; // npm install aws-sdk
import { createStyles, makeStyles } from '@mui/styles';
import AWS from 'aws-sdk';
import awsExports from 'src/aws-exports'; // Import your AWS Amplify configuration file

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2rem',
      padding: '1rem',
      [theme.breakpoints.up('sm')]: {
        marginTop: '2rem',
      },
    },
    formControl: {
      width: '100%',
      '& label.Mui-focused': {
        color: '#0066cc',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#0066cc',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'rgba(0,0,0,0.1)',
        },
        '&:hover fieldset': {
          borderColor: 'rgba(0,0,0,0.3)',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#0066cc',
        },
      },
    },
    imagePreview: {
      width: '100%',
      maxHeight: '200px',
      objectFit: 'cover',
      borderRadius: '10px',
      [theme.breakpoints.up('sm')]: {
        maxHeight: '140px',
      },
    },
  })
);


const shortDescriptionTemplates = [
  "Help support a talented racer in their quest for victory!",
  "Join us in funding a passionate motorsport athlete!",
  "Contribute to the success of an aspiring motorsport champion!",
];

export function CreateCampaign(props: any) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [name, setName] = React.useState('')
  const [link_video, setLink_video] = React.useState('');
  const [image, setImage] = React.useState('');
  const [link_thumbnail, setLink_thumbnail] = React.useState('');
  const [qrimageurl, setQrimageurl] = React.useState('');
  const [fund_goal, setFund_goal] = React.useState(0.0);

  const [typeOfRacing, setTypeOfRacing] = React.useState('')

  const [startstamp, setStartstamp] = React.useState<Date | null>(new Date());
  const [endstamp, setEndstamp] = React.useState<Date | null>(new Date());
  const [managerID, setManagerID] = React.useState('');
  const [owner, setOwner] = React.useState('');

  const [campaignId, setCampaignId] = React.useState('')

  const [short_description, setShortDescription] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [selectedTemplate, setSelectedTemplate] = React.useState('');

  async function expandShortDescription(shortDescription: string): Promise<string> {
    try {
      const payload = {
        prompt: `Create a marketing copy for a motor sports racer on the following description, it should be ready to be published: Campaign Name: ${name} Type of racing: ${typeOfRacing} Short description: ${shortDescription}`,
      };

      const credentials = await Auth.currentCredentials();
      const lambda = new Lambda({
        region: awsExports.aws_project_region, // Add the region from the Amplify configuration file
        credentials: Auth.essentialCredentials(credentials),
      });
      const response = await lambda
        .invoke({
          FunctionName: 'ChatGPTFundracer-devjim',
          Payload: JSON.stringify(payload),
        })
        .promise();
      console.log(response);
      const responsePayload = JSON.parse(response.Payload as string);
      const body = JSON.parse(responsePayload.body);
      const expandedDescription = body.expandedDescription;

      if (responsePayload && expandedDescription) {
        return expandedDescription.trim();
      } else {
        throw new Error("No expanded description received from Lambda function");
      }
    } catch (error) {
      console.error("Error calling Lambda function:", error);
      throw error;
    }
  }

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  async function handleGenerateClick() {
    try {
      const expandedDescription = await expandShortDescription(short_description);
      setDescription(expandedDescription);
    } catch (error) {
      console.error("Error generating expanded description:", error);
      // You may want to display an error message to the user
    }
  }

  async function createNewCampaign(campaign: Campaign) {
    // Create Campaign Code start here... Insert Campaign to AWS
    const newcampaigndata: any = await API.graphql({
      query: createCampaign,
      variables: { input: campaign },
    });
    const campaignid = newcampaigndata.data.createCampaign.id;
    setCampaignId(campaignid)
    console.log(campaignid)

    //Create QR code using QR Tiger API start here . .........................
    // const urlforqr = 'http://localhost:3000/campaign/' + campaignid;
    const urlforqr = 'https://www.fundracer.net/campaign/' + campaignid;
    console.log(urlforqr);


    // Note that you much have a qrcode-tiger.com API key to generate dynamic QR codes pngs
    // The free version of this account will only allow 3 free dynamic QR code pngs

    /*Code to generate dynamic QR codes*/
    const requestOptionsDynamicQR = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer 7ae537d0-8e6b-11ec-a67d-d3b57b01f9ad' },
      body: JSON.stringify({
        qr: {
          size: 500,
          colorDark: 'rgb(5,64,128)',
          logo: '',
          eye_outer: 'eyeOuter2',
          eye_inner: 'eyeInner1',
          qrData: 'pattern0',
          backgroundColor: 'rgb(255,255,255)',
          transparentBkg: false,
          qrCategory: 'url',
          text: urlforqr,
        },
        qrUrl: urlforqr,
        qrType: 'qr',
        qrCategory: 'url',
      }),
    };

    //A free QR Tiger account will allow 100 API calls for static QR code pngs
    //Note the POST response for the static QR is a png not a json object compared to dynamic QRs
    /* Update Authorization API key to enable POST call */
    /* TODO Fix code to hide API key in .env file */

    const requestOptionsStaticQR = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer 7ae537d0-8e6b-11ec-a67d-d3b57b01f9ad' },
      body: JSON.stringify({
        size: 500,
        colorDark: 'rgb(5,64,128)',
        logo: 'scan_me.png',
        eye_outer: 'eyeOuter2',
        eye_inner: 'eyeInner1',
        qrData: 'pattern0',
        backgroundColor: 'rgb(255,255,255)',
        transparentBkg: false,
        qrCategory: 'url',
        text: urlforqr
      })
    };

    //Generate static QR using requestOptionsStaticQR option - use requestOptionsDynamicQR for dynamic QRs
    const response = await fetch('https://qrtiger.com/api/qr/static/', requestOptionsStaticQR);
    const data = await response.json();
    console.log(data.url)

    //Here we get the QR Image url so we are starting UPDATE campaign record from here.......
    const updateCampDetail = {
      id: campaignid,
      qrimageurl: data.url,
    };
    const updateQRimageurl = await API.graphql({ query: updateCampaign, variables: { input: updateCampDetail } });

    // Navigate to admin/viewcampaing/campaignid page 
    navigate('../viewcampaign/' + campaignid);


  }
  useEffect(() => {
    // Need ***** to put it somewhere else (In redux or as a context)
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        setManagerID(user.signInUserSession.accessToken.payload.username);
        setOwner(user.signInUserSession.accessToken.payload.username);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleImageUpload = async (e: React.ChangeEvent) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    const files: FileList = target.files!;
    const file: File = files[0];
    const extension = file.name.split('.')[1];
    const name = file.name.split('.')[0];
    const key = `images/${uuidv4()}${name}.${extension}`;
    const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`;
    try {
      // Upload the file to s3 with public access level.
      await Storage.put(key, file, {
        level: 'public',
        contentType: file.type,
      });
      // Retrieve the uploaded file to display
      const image = await Storage.get(key, { level: 'public' });
      setImage(image);
      setLink_thumbnail(url);
    } catch (err) {
      console.log(err);
    }
  };

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let oldDateFormat: any = startstamp && startstamp.toString();
    let startstamp2 = new Date(oldDateFormat).toISOString();
    let oldDateFormat2: any = endstamp && endstamp.toString();
    let endstamp2 = new Date(oldDateFormat2).toISOString();

    // Check if the link_video is empty
    const linkVideo = link_video.trim() !== '' ? link_video : null;

    const campaign: Campaign = {
      name,
      short_description,
      description,
      link_video: linkVideo,
      fund_goal,
      managerID,
      qrimageurl,
      start_at: startstamp2,
      end_at: endstamp2,
      link_thumbnail: link_thumbnail,
    };

    createNewCampaign(campaign);
  };

  return (
    <div className={classes.root}>
      <form onSubmit={submitHandler}>
        <Card>
          <CardHeader title="Start New Campaign" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={3}>
                  <TextField
                    id="name"
                    value={name}
                    onChange={(newValue) => setName(newValue.target.value)}
                    label="Campaign Name"
                    variant="outlined"
                    fullWidth
                  />
                  <TextField
                    id="typeOfRacing"
                    value={typeOfRacing}
                    onChange={(typeOfRacing) => setTypeOfRacing(typeOfRacing.target.value)}
                    label="Racing Type"
                    variant="outlined"
                    fullWidth
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={3}>
                  <TextField
                    id="short-description"
                    label="Short Description"
                    multiline
                    rows={1}
                    variant="outlined"
                    value={selectedTemplate || short_description}
                    onChange={(event) => setShortDescription(event.target.value)}
                    disabled={Boolean(selectedTemplate)}
                    fullWidth
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="template-label">Short Description Template</InputLabel>
                  <Select
                    labelId="template-label"
                    id="template-select"
                    value={selectedTemplate}
                    onChange={handleTemplateChange}
                    label="Short Description Template"
                  >
                    <MenuItem value="">None</MenuItem>
                    {shortDescriptionTemplates.map((template, index) => (
                      <MenuItem key={index} value={template}>
                        {template}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  variant="outlined"
                  label="Description"
                  multiline
                  rows={8}
                  fullWidth
                />
                <Button variant="outlined" onClick={handleGenerateClick} fullWidth>
                  Generate
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} fullWidth />}
                    label="Start Date & Time"
                    value={startstamp}
                    onChange={(newValue) => {
                      setStartstamp(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} fullWidth />}
                    label="End Date & Time"
                    value={endstamp}
                    onChange={(newValue) => {
                      setEndstamp(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="fund_goal"
                  value={fund_goal}
                  onChange={(newValue) => setFund_goal(Number(newValue.target.value))}
                  label="Fund Goal"
                  type="number"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="link_video"
                  value={link_video}
                  onChange={(newValue) => setLink_video(newValue.target.value)}
                  label="Link for Video"
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  id="link_thumbnail"
                  type="file"
                  onChange={(e) => handleImageUpload(e)}
                  variant="outlined"
                  fullWidth
                />
                {image && (
                  <CardMedia
                    className={classes.imagePreview}
                    component="img"
                    image={image}
                    alt="Campaign Thumbnail"
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Submit
                </Button>
              </Grid>
              <Grid item xs={12}>
                {campaignId && (
                  <Paper elevation={3} style={{ backgroundColor: 'LightGreen', display: 'flex', justifyContent: 'center', border: '1px solid black', padding: '1rem' }}>
                    <Typography variant="h6">
                      <b>New campaign successfully submitted</b>
                    </Typography>
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12}>
                {campaignId && (
                  <Paper elevation={3} style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                    <Typography variant="h6">
                      <b>Campaign ID: {campaignId}</b>
                    </Typography>
                  </Paper>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </div>
  );
}
