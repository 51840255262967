import React, { useEffect, useState } from 'react';
import { Grid, Paper, FormControl, TextField, Stack, Button, Typography, Box, LinearProgress, Modal} from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';




export const NotFound: React.FC = (props) => {
	return (
		<div>
			<h1>Error</h1>
			<p>Sorry, an error occurred.</p>
		</div>
	);
};
