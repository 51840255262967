import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { API, graphqlOperation, Auth } from 'aws-amplify';
import { listCampaigns } from 'src/graphql/queries';
import CardPostDash from 'src/component/publiccomponent/CardPostDash';
import { Link } from 'react-router-dom';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export function Dashboard(props: any) {
  const [userName, setUserName] = React.useState('');
  const [user, setUser] = React.useState<any>(undefined);
  const [ManagerIDLogged, setManagerIDLogged] = React.useState('');
  const [campaigns, setCampaigns] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedCampaign, setSelectedCampaign] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState('');
  const [socialMediaOptions, setSocialMediaOptions] = React.useState([
    {
      name: 'Facebook',
      icon: <FacebookIcon />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
    },
    {
      name: 'Twitter',
      icon: <TwitterIcon />,
      link: `https://twitter.com/intent/tweet?url=${shareUrl}`,
    },
    {
      name: 'LinkedIn',
      icon: <LinkedInIcon />,
      link: `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`,
    },
    {
      name: 'Instagram',
      icon: <InstagramIcon />,
      link: `https://www.instagram.com/?url=${shareUrl}`,
    },
    {
      name: 'Copy Link',
      icon: <FileCopyIcon />,
      link: shareUrl,
    }, 
  ]);


  async function getCampaigns() {
    const response = await API.graphql({ query: listCampaigns, authMode: 'API_KEY' });
    const { data } = response as { data?: any };

    if (data && data.listCampaigns && data.listCampaigns.items) {
      const managerID = ManagerIDLogged;
      const campaigns = data.listCampaigns.items.filter(campaign => campaign.managerID === managerID);
      setCampaigns(campaigns);
    }
  }

  React.useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        setUser(user);
        setUserName(user.attributes.name + " " + user.attributes.family_name);
        setManagerIDLogged(user.signInUserSession.accessToken.payload.username);
      })
      .catch((err) => console.log(err));
  }, []);

  React.useEffect(() => {
    if (ManagerIDLogged) {
      getCampaigns();
    }
  }, [ManagerIDLogged]);

  const handleModalOpen = (campaign) => {
    setSelectedCampaign(campaign);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedCampaign(null);
    setModalOpen(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Welcome, {userName}</h2>
      </Box>
      <Box sx={{ display: 'flex', flexDirection:  'column', fontWeight: 'bold', marginBottom:'30px', lineHeight: '150%'}}>
        <div>Your Last Name : {user?.attributes?.family_name}</div>
        <div>Your First Name : {user?.attributes?.name}</div>
        <div>Your Email: {user?.attributes?.email}</div>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Campaign Name</TableCell>
            <TableCell align='right'>Short Description</TableCell>
            <TableCell align='right'>Duration</TableCell>
            <TableCell align='right'>Fund Goal</TableCell>
            <TableCell align='right'>Share Fundraiser</TableCell>
            <TableCell align='right'>Campaign site</TableCell>
            <TableCell align='right'>Edit Campaign</TableCell>
          </TableRow>
        </TableHead>
          <TableBody>
            {campaigns.length
              ? campaigns.map((row: any) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component='th' scope='row'>
                      <Link to={'./viewcampaign/' + row.id}> {row.name}</Link>
                    </TableCell>
                    <TableCell align='right'>{row.short_description}</TableCell>
                    <TableCell align='right'>
                      {new Date(row.start_at).toLocaleDateString('en-US') +
                        ' to ' +
                        new Date(row.end_at).toLocaleDateString('en-US')}
                    </TableCell>
                    <TableCell align='right'>{'$' + row.fund_goal}</TableCell>
                    <TableCell align='right'>
                      {/* Replace the button with a Material-UI Button component */}
                      <Button variant='contained' onClick={() =>{ setShowModal(true); 
                          setShareUrl(`https://fundracer.net/campaign/${row.id}`)}}>Share</Button>
  
                      {/* Add a modal component */}
                      <Modal
                        open={showModal}
                        onClose={() => setShowModal(false)} 
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: 400,
                          bgcolor: 'background.paper',
                          boxShadow: 24,
                          p: 4,
                          borderRadius: 4,
                        }}>
                          <h2 id="modal-modal-title">Share Campaign</h2>
                          <div>
                            {socialMediaOptions.map((option, index) => (
                              <a 
                                key={index}
                                href={`${option.link}${shareUrl}`}
                                target="_blank" 
                                rel="noreferrer"
                              >
                                <div>{option.name} {option.icon}</div>
                              </a>
                            ))}
                          </div>
                        </Box>
                      </Modal>
                    </TableCell>
                    <TableCell align='right'>
                      <Link to={'../campaign/' + row.id}> Campaign site</Link>
                    </TableCell>
                    <TableCell align='right'>
                      <Button variant='contained' color='primary' component={Link} to={'./editcampaign/' + row.id}>
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              :  (
                <TableRow>
                  <TableCell colSpan={6} align='center'>No campaigns available.</TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );  
}

export default Dashboard;