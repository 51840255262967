import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

export const MenuListItems = (
	<React.Fragment>
		<ListItemButton>
			<Link to='./createcampaign'>
				<Button variant='contained' color='primary' sx={{ height: 40 }}>
					Start New Campaign
				</Button>
			</Link>
		</ListItemButton>
		<Link to=''>
			<ListItemButton>
				<ListItemIcon>
					<DashboardIcon />
				</ListItemIcon>
				<ListItemText primary='Dashboard' />
			</ListItemButton>
		</Link>

		<Link to='setting'>
			<ListItemButton>
				<ListItemIcon>
					<LayersIcon />
				</ListItemIcon>
				<ListItemText primary='Settings' />
			</ListItemButton>
		</Link>

		<Link to='campaigns'>
			<ListItemButton>
				<ListItemIcon>
					<LayersIcon />
				</ListItemIcon>
				<ListItemText primary='Campaign' />
			</ListItemButton>
		</Link>

		<Link to='/home'>
			<ListItemButton>
				<ListItemIcon>
					<LayersIcon />
				</ListItemIcon>
				<ListItemText primary='Home' />
			</ListItemButton>
		</Link>
	</React.Fragment>
);


export const adminMenuListItems = (
	<React.Fragment>
		<ListItemButton>
			<Link to='./createcampaign'>
				<Button variant='contained' color='primary' sx={{ height: 40 }}>
					Start New Campaign
				</Button>
			</Link>
		</ListItemButton>
		<Link to=''>
			<ListItemButton>
				<ListItemIcon>
					<DashboardIcon />
				</ListItemIcon>
				<ListItemText primary='Dashboard' />
			</ListItemButton>
		</Link>

		<Link to='campaigns'>
			<ListItemButton>
				<ListItemIcon>
					<LayersIcon />
				</ListItemIcon>
				<ListItemText primary='Campaign' />
			</ListItemButton>
		</Link>

		<Link to='/home'>
			<ListItemButton>
				<ListItemIcon>
					<LayersIcon />
				</ListItemIcon>
				<ListItemText primary='Home' />
			</ListItemButton>
		</Link>

		<Link to='setting'>
			<ListItemButton>
				<ListItemIcon>
					<LayersIcon />
				</ListItemIcon>
				<ListItemText primary='Settings' />
			</ListItemButton>
		</Link>

		<Link to='./AdminsPage'>
			<ListItemButton>
				<ListItemIcon>
					<LayersIcon />
				</ListItemIcon>
				<ListItemText primary='Admin' />
			</ListItemButton>
		</Link>
	</React.Fragment>
);


export const managerMenuListItems = (
	<React.Fragment>
		<Link to='./'>
			<ListItemButton>
				<ListItemIcon>
					<DashboardIcon />
				</ListItemIcon>
				<ListItemText primary='Dashboard' />
			</ListItemButton>
		</Link>

		<Link to='campaigns'>
			<ListItemButton>
				<ListItemIcon>
					<LayersIcon />
				</ListItemIcon>
				<ListItemText primary='Campaign' />
			</ListItemButton>
		</Link>
	</React.Fragment>
);

export const donorMenuItems = (
	<React.Fragment>
		<Link to='donorcampaigns'>
			<ListItemButton>
				<ListItemIcon>
					<AssignmentIcon />
				</ListItemIcon>
				<ListItemText primary='Campaings' />
			</ListItemButton>
		</Link>
	</React.Fragment>
);
export const secondaryListItems = (
	<React.Fragment>
		<ListSubheader component='div' inset>
			Saved reports
		</ListSubheader>
		<ListItemButton>
			<ListItemIcon>
				<AssignmentIcon />
			</ListItemIcon>
			<ListItemText primary='Current month' />
		</ListItemButton>
		<ListItemButton>
			<ListItemIcon>
				<AssignmentIcon />
			</ListItemIcon>
			<ListItemText primary='Last quarter' />
		</ListItemButton>
		<ListItemButton>
			<ListItemIcon>
				<AssignmentIcon />
			</ListItemIcon>
			<ListItemText primary='Year-end sale' />
		</ListItemButton>
	</React.Fragment>
);
