import React from 'react';
import Template from './template/Template';
// import './App.css';

function App() {
	return <Template />;
}

export default App;

// import React from 'react';

// import { withAuthenticator } from '@aws-amplify/ui-react';
// import '@aws-amplify/ui-react/styles.css';

// function App() {
//   return (
//     <div className="App">
//     <h1>   Hello World</h1>
//     </div>
//   );
// }

// export default withAuthenticator(App);
