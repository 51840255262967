// Import required libraries and components
import React from 'react';
import MainFeaturedPost from 'src/component/publiccomponent/MainFeaturePost';
import FeaturedPost from 'src/component/publiccomponent/FeaturePost';
import RecommendedPost from 'src/component/publiccomponent/RecommendedPost';
import NewArrivalPost from 'src/component/publiccomponent/NewArrivalPost';
import { Grid, Typography, Box } from '@mui/material';
import { Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Post1 from './assets/image/poster1.jpg';
	import Post2 from './assets/image/poster2.jpg';
	import Post3 from './assets/image/poster3.jpg';
	import Post4 from './assets/image/poster4.jpg';

import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';

// Homepage functional component
function Homepage() {


	// Data for the main featured post and the two featured posts
	const mainFeaturedPost = [
		{
			title: 'Kickstart Your Fundraiser',
			description: 'Dreaming of your own racing venture? Launch a campaign to get the financial boost you need.',
			image: Post1,
			imageText: 'Start a campaign',
			button: 'Start a New Campaign'
		},
		{
			title: 'Seeking Funds for Your Dream?',
			description: 'Get the resources you need to make your racing dreams a reality. Start your fundraiser today.',
			image: Post2,
			imageText: 'Start a campaign',
			button: 'Start a New Campaign'
		},
		{
			title: 'Support a Racing Campaign',
			description: 'Looking to contribute? Explore campaigns and help bring someone’s racing dreams to life.',
			image: Post3,
			imageText: 'Support a campaign',
			button: 'See Campaigns'
		},
		{
			title: 'Help Fund a Racing Dream',
			description: 'Discover campaigns you can support and help make racing dreams come true.',
			image: Post4,
			imageText: 'Support a campaign',
			button: 'See Campaigns'
		},
	];

	// const featuredPosts = {
	// 	title: 'Popular Campaigns',
	// 	content: [
	// 		{
	// 			title: 'Spotlight Campaign: Track Titans',
	// 			date: 'Jul 12',
	// 			description: 'One of the most viewed campaigns, Track Titans aims to revolutionize the racing industry.',
	// 			image: 'https://media.istockphoto.com/photos/leading-the-race-picture-id181054095?k=20&m=181054095&s=612x612&w=0&h=E_5XCsebwJKAQmLC31DcSKD7r_L3rM7ikNIGXQZme9Q=',
	// 			imageLabel: 'Leading the race',
	// 		},
	// 		{
	// 			title: 'Recommended for You: Road Runners',
	// 			date: 'Jun 11',
	// 			description: 'Based on your donation history, you might be interested in the Road Runners campaign, striving to push the boundaries of speed.',
	// 			image: 'https://porschecarshistory.com/wp-content/old/allin2/22/01.jpg',
	// 			imageLabel: 'Speed on the road',
	// 		},
	// 	],
	// };

	// Return JSX expression that renders components, including logo and posts
	return (
		<div>
			{/* Render logo */}
			<Box
				sx={{
					p: 0.5,
					m: 2,
					borderRadius: 2,
					textAlign: 'center',
					fontSize: '2.5rem',
					fontWeight: '700',
					'& img': {
						maxWidth: '100%',
						height: 'auto',
						padding: '0 50px',
					},
				}}
			>
				<img src='https://cdn.discordapp.com/attachments/693032814169555019/1092534882950983785/image.png' alt="logo" style={{ maxWidth: '30%', height: 'auto' }} />
			</Box>
			{/* Render main featured post */}
			<MainFeaturedPost post={mainFeaturedPost} />

			{/* Render featured posts */}
			{/* <Typography variant='h5' align='center' style={{ marginTop: '30px' }}>
				{"Popular Campaigns"}
			</Typography> */}

			<Grid item xs={12} md={6}>
				<FeaturedPost />
			</Grid>



			{/* Render new arrival post */}
			<Grid sx={{ mt: 5 }}>
				<NewArrivalPost />
			</Grid>
		</div>
	);
}

export default Homepage;