import React, { useEffect, useState } from 'react';
import { Grid, Paper, FormControl, TextField, Stack, Button, Typography, Box, LinearProgress, Modal} from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { getCampaign } from 'src/graphql/queries';
import { listDonationrecords } from 'src/graphql/queries';
import { deleteCampaign } from 'src/graphql/mutations';
import { Campaign } from 'src/models';
import { API, Auth } from 'aws-amplify';
import { Memberlistincampaign } from '../members';
import { Rewardlistincampaign } from '../rewardcategories';
import { Donationlistcampaign } from '../Donationrecords/Donationlistcampaign';


export const ViewCampaign: React.FC = (props) => {
	let paramdata = useParams();
	const [onecampaign, setOnecampaign] = useState<Campaign>();
	const [donationTotalCalc, setDonationTotal] = useState(0);
	const [showModal, setShowModal] = React.useState(false);
	//const [userIDLogged, setUserIDLogged] = React.useState('');
	const navigate = useNavigate();

	async function viewgetcampaign(id: any, userID: any, groups: any) {

		console.log(id);
		console.log(groups);
		//console.log("User: " + userIDLogged)
		
		const data2: any = await API.graphql({
			query: getCampaign,
			variables: { id },
			authMode: 'API_KEY',
		});

		console.log("User: " + userID);
		//console.log(data2.data.getCampaign);

		if (data2.data.getCampaign != undefined) {
			const campaign = data2.data.getCampaign;
			if (campaign.CDonationrecords !== undefined && Array.isArray(campaign.CDonationrecords.items)) {
				if (data2.data.getCampaign.managerID == userID || groups == 'Admin'){

					setOnecampaign(campaign);
					//console.log(campaign.CDonationrecords.items);
					const donationTotal = campaign.CDonationrecords.items.reduce((sum, item) => sum + (item?.amount || 0), 0);
					setDonationTotal(donationTotal);
					console.log(donationTotal);
				}else{
					
			
					console.log("not found B");
					errorPage();
				}
				
			  }
		}else{
			console.log("not found A");
			errorPage();
			
		}
		
	}

	async function errorPage(){
		console.log("not found A");
		navigate("../not-found");
	}

	async function handleDeleteCampaign() {
		try {
			setShowModal(false);
			const input = { id: paramdata.campaignID }; // Set the input object with the campaign ID
			await API.graphql({
			  query: deleteCampaign,
			  variables: { input }, // Pass the input object as variables
			  authMode: 'AMAZON_COGNITO_USER_POOLS'
			});
			// Handle any necessary logic after the campaign deletion
		  } catch (error) {
			console.log('Error deleting campaign:', error);
		  }
		}


	async function getUserID() {
		try {
			//console.log("Doing thing");
			  const user = await Auth.currentAuthenticatedUser({
				bypassCache: false,
			  });
			  //console.log("user id will be " + user.signInUserSession.accessToken.payload.username );
			  //setUserIDLogged(user.signInUserSession.accessToken.payload.username);
			  let groups = user.signInUserSession.accessToken.payload['cognito:groups'];
			  if (groups == undefined){
				viewgetcampaign(paramdata.campaignID, 
					user.signInUserSession.accessToken.payload.username,null);
			  }else{
				viewgetcampaign(paramdata.campaignID, 
				user.signInUserSession.accessToken.payload.username,
				groups[0]);
			  }

			  
			  //console.log(userIDLogged);
			} catch (error) {
			  console.log(error);
			  errorPage()
			}
		}
		  
	useEffect(() => {
		async function fetchData() {
			await getUserID();
			//await viewgetcampaign(paramdata.campaignID);
		  }
		fetchData();
	}, []);

/*
	useEffect(() => {
		console.log("User is: " + userIDLogged);
	  }, [userIDLogged]);*/

		  
		 // console.log("user id is abc " + userIDLogged);

	return (
		<><Typography variant='body1'>Campaign ID: {paramdata.campaignID}</Typography>
		<Box display='flex'>
			<FormControl fullWidth={true} component={Paper}>
			<Box m={1} display='flex' justifyContent='flex-end' alignItems='flex-end'>
				<Button variant='contained' sx={{ height: 40, backgroundColor: '#FF8000', marginLeft: '-10px' }}>
				<Link to={`../createmember/${onecampaign?.id}`} style={{ color: '#000000', textDecoration: 'none',fontWeight: 'bold' }}>Add New Member</Link>
				</Button>
				<Button variant='contained' sx={{ height: 40, backgroundColor: '#FF8000' }}>
				<Link to={`../createrewardcategory/${paramdata.campaignID}`} style={{ color: '#000000', textDecoration: 'none',fontWeight: 'bold' }}>Add New Reward Category</Link>
				</Button>
				<Button variant='contained' sx={{ height: 40, backgroundColor: '#FF8000' }} onClick={() => setShowModal(true)} style={{ color: '#000000', fontWeight: 'bold' }}>
					Delete Campaign
				</Button>

				<Modal
                        open={showModal}//handleDeleteCampaign}
                        onClose={() => setShowModal(false)} 
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: 400,
                          bgcolor: 'background.paper',
                          boxShadow: 24,
                          p: 4,
                          borderRadius: 4,
                        }}>
                          <div className="modal">
							<div className="modal-content">
								<h3>Are you sure you want to delete?</h3>
								<Link to={`/admin/campaigns`}>
								<button onClick={handleDeleteCampaign} style={{ marginRight: '10px' }}>Delete
								</button></Link>
								<button onClick={() => setShowModal(false)}>
									Cancel
								</button>
							</div>
							</div>
                        </Box>
                      </Modal>



			</Box>				
			
			<Stack spacing={2} ml={10} mr={10} mb={5} sx={{ marginTop: '-50px' }}>
				<h1>{onecampaign?.name} </h1>

				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<img src={onecampaign?.link_thumbnail} 
						style={{
							width: '55%'
						}} 
					/>
				</div>
				<Typography variant='h5' color='text.secondary' sx={{ position: 'relative', top: 0 , fontWeight: 'bold', color: '#000000'}}>
							${donationTotalCalc} of ${onecampaign?.fund_goal} recieved
							</Typography >
				<div> 
				<LinearProgress variant = 'determinate'
								value={donationTotalCalc ? Math.min((donationTotalCalc / (onecampaign?.fund_goal || 0)) * 100, 100) : 0}
								sx={{
									position: 'absolute',
									//top: 700, 
									borderRadius: 100,
									width: '80%',
									height: 30,
								}}/>
				</div>
				<div></div>	<div></div>				
				<Stack spacing={2} sx={{ textAlign: 'left', marginRight: '100px' }}>
					<Typography variant='body1'>Short Description: {onecampaign?.short_description}</Typography>
					<Typography variant='body1'>Description: {onecampaign?.description}</Typography>
					<Typography variant='body1'>Start Date Time: {onecampaign?.start_at ? new Date(onecampaign.start_at).toLocaleDateString() : 'Error'}</Typography>
					<Typography variant='body1'>End Date Time: {onecampaign?.end_at ? new Date(onecampaign?.end_at).toLocaleDateString() : 'Error'}</Typography>
					<Typography variant='body1'>Fund Goal : ${onecampaign?.fund_goal}</Typography>
					<Typography variant='body1'>Funds Received: ${donationTotalCalc}</Typography>
				</Stack>
			</Stack>
					<Stack spacing={2} ml={3} mr={3} mb={3}>
						<Memberlistincampaign Membersdata={onecampaign?.CCampaignmembers} />
						<Rewardlistincampaign Rewardsdata={onecampaign?.CRewardcategories} />
						<Donationlistcampaign DonationData={onecampaign?.CDonationrecords} />
					</Stack>

					
				
			</FormControl>
		</Box></>
	);
};