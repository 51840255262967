import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { adminMenuListItems,MenuListItems, managerMenuListItems, donorMenuItems, secondaryListItems } from './sidebaritem';
import { Auth } from 'aws-amplify';

function Adminsidebar(props: any) {
	const drawerWidth: number = 240;
	const [groupname, setGroupname] = useState('');
	useEffect(() => {
		Auth.currentAuthenticatedUser({
		  bypassCache: false,
		})
		.then((user) => {
		  const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
		  if (groups && groups.length > 0) {
			//console.log(groups[1]);
			setGroupname(groups[0]);
		  }
		})
		.catch((err) => console.log(err));
	  }, []);
	  
	let sidebar;
	//console.log(groupname);
	if (groupname == 'Admin') {
		sidebar = adminMenuListItems;
		//sidebar = managerMenuListItems;
	} else if (groupname == 'Manager') {
		sidebar = MenuListItems;
		//sidebar = managerMenuListItems;
	} else if (groupname == 'Donor' || groupname.includes('Facebook') || groupname.includes('Google')) {
		sidebar = MenuListItems;
		//sidebar = donorMenuItems;
	}else{
		sidebar = MenuListItems;
	}
	

	const Drawer = styled(MuiDrawer, {
		shouldForwardProp: (prop) => prop !== 'open',
	})(({ theme, open }) => ({
		'& .MuiDrawer-paper': {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: 'border-box',
			...(!props.open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: theme.spacing(7),
				[theme.breakpoints.up('sm')]: {
					width: theme.spacing(9),
				},
			}),
		},
	}));
	return (
		<Drawer variant='permanent' open={props.open}>
			<Toolbar
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					px: [1],
				}}
			>
				<IconButton onClick={props.toggleDrawer}>
					<ChevronLeftIcon />
				</IconButton>
			</Toolbar>
			<Divider />
			<List component='nav'>{sidebar}</List>
		</Drawer>
	);
}

export default Adminsidebar;
