import React, { useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay, Navigation } from 'swiper';
import { useNavigate } from 'react-router-dom';

interface MainFeaturedPostProps {
	post: {
		image: string;
		item: string;
		description: string;
	};
}

const left = require('../../pages/publicpages/assets/image/left.png');
const right = require('../../pages/publicpages/assets/image/right.png');

function MainFeaturedPost(props) {
	const [index, setIndex] = useState(0);
	const { post } = props;
	const navigate = useNavigate();

	const navigation = {
		prevEl: ".leftIcon",
		nextEl: ".rightIcon",
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
			<div
				onClick={() => {
					index === 0 || index === 1 ?
						navigate('/admin/createcampaign') : navigate('/campaigns')
				}}
				style={{
					position: 'absolute',
					color: 'white',
					bottom: '90px',
					fontSize: '16px',
					fontWeight: 800,
					borderBottom: '2px solid white',
					cursor: 'pointer',
					zIndex: 99999,
				}}
			>
				{index === 0 || index === 1 ? 'START A NEW CAMPAIGN' : 'GO CAMPAIGNS'}
			</div>
			<div
				style={{
					display: 'flex', justifyContent: 'space-between', width: '80px',
					position: 'absolute', bottom: '40px', zIndex: 99999
				}}
			>
				<img src={left} alt='' style={{ height: '30px' }} className='leftIcon' />
				<img src={right} alt='' style={{ height: '30px' }} className='rightIcon' />
			</div>
			<Swiper
				pagination={{
					clickable: true
				}}
				navigation={navigation}
				autoplay={{
					delay: 5000,  // Increase the delay to slow down the swiper
					disableOnInteraction: false,
				}}
				speed={3000}  // Reduce the speed of the swiper
				loop={true}
				modules={[Autoplay, Navigation]}
				className='mySwiper'
				onSlideChange={(index) => setIndex(index.realIndex)}
			>
				{post.map((item, index) => {
					return (
						<SwiperSlide style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} key={index}>
							<img src={item.image} alt='' style={{
									width: '75%',
									height: '800px',
									borderRadius: '10px',
									backgroundImage: `url(${item.image})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center'
								}} />
							<Box style={{ position: 'absolute', color: 'white', bottom: '160px', fontSize: '30px', fontWeight: 600, textShadow: '1px 5px rgba(0, 0, 0, 0.5)' }}>
								{item.title}
							</Box>
							<Box style={{ position: 'absolute', color: 'white', bottom: '120px', fontSize: '22px', fontWeight: 400, textShadow: '1px 1px 5px rgba(0, 0, 0, 0.5)' }}>
								{item.description}
							</Box>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
}

export default MainFeaturedPost;