// import React from 'react';
// import CardPost from './CardPost';

// function RecommendedPost() {
// 	const cardinraw: number = 3; // values will be divided by 12 to get # card in raw
// 	const title = 'Recommended Campaigns';
// 	return <>{/* <CardPost title={title} cardinraw={cardinraw} /> */}</>;
// }
// export default RecommendedPost;



/*Campaigns sorted by highest to lowest donations received */
import { API, graphqlOperation } from 'aws-amplify';
import React from 'react';
import { listCampaigns } from 'src/graphql/queries';
import CardPost from './CardPost';
import { Campaign } from 'src/models';

function RecommendedPost() {
  const cardsPerRow = 3;
  const title = 'Recommended Campaigns';

  const [campaigns, setCampaigns] = React.useState<Campaign[]>([]);

  async function getCampaigns() {
    const data: any = await API.graphql({ query: listCampaigns, variables: { limit: 3 }, authMode: 'API_KEY' });

    if (data.data.listCampaigns.items !== undefined) {
      const sortedCampaigns = data.data.listCampaigns.items.sort((a: Campaign, b: Campaign) => {
        if (a.donation_received && b.donation_received) {
          return b.donation_received - a.donation_received;
        }
        if (!a.donation_received) return 1;
        if (!b.donation_received) return -1;
        return 0;
      });

      setCampaigns(sortedCampaigns);
    }
  }

  React.useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <>
      <CardPost title={title} cardinraw={cardsPerRow} posts={campaigns} />
    </>
  );
}

export default RecommendedPost;


/*Randomly choosen campaigns

import { API, graphqlOperation } from 'aws-amplify';
import React from 'react';
import { listCampaigns } from 'src/graphql/queries';
import CardPost from './CardPost';
import { Campaign } from 'src/models';

function RecommendedPost() {
  const cardsPerRow = 4;
  const title = 'Recommended Campaigns';

  const [campaigns, setCampaigns] = React.useState<Campaign[]>([]);

  async function getCampaigns() {
    const data: any = await API.graphql({ query: listCampaigns, variables: { limit: 3 }, authMode: 'API_KEY' });

    if (data.data.listCampaigns.items !== undefined) {
      // Shuffle the campaigns array
      const shuffledCampaigns = shuffle(data.data.listCampaigns.items);
      // Select the first three campaigns (change the number as per your requirement)
      const selectedCampaigns = shuffledCampaigns.slice(0, 3);
      // Set campaigns state with the selected campaigns
      setCampaigns(selectedCampaigns);
    }
  }

  function shuffle(array: any[]) {
    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  React.useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <>
      <CardPost title={title} cardinraw={cardsPerRow} posts={campaigns} />
    </>
  );
}

export default RecommendedPost;

*/