import { API, graphqlOperation } from 'aws-amplify';
import React from 'react';
import { listCampaigns } from 'src/graphql/queries';
import CardPost from './CardPost';
import { Campaign } from 'src/models';

function NewArrivalPost() {
  const cardsPerRow = 4;
  const title = 'Newly Launched';

  const [campaigns, setCampaigns] = React.useState<Campaign[]>([]);
  async function getCampaigns() {
    const data: any = await API.graphql({ query: listCampaigns, variables: { limit: 4 }, authMode: 'API_KEY' });
    if (data.data.listCampaigns.items !== undefined) {
      setCampaigns(data.data.listCampaigns.items);
    }
  }

  React.useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <>
      <CardPost title={title} cardinraw={cardsPerRow} posts={campaigns} />
    </>
  );
}

export default NewArrivalPost;
