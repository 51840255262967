import * as React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PageTitle from 'src/component/publiccomponent/PageTitle';
import AboutContent from 'src/component/publiccomponent/AboutFeature/AboutUsContent';

function About() {
	const backgrd = require('./campaign1.jpg');
	const bg = require('../../assets/about_us_bg.jpg');
	const aboutFeature = {
		title: 'About FundRacer',
		image: bg,
		bgText: 'FundRacer: Fueling dreams of racing enthusiasts. We provide a robust online fundraising platform for non-profit organizations, aiding in accomplishing race goals and turning dreams into reality.',
		page: 'about'
	};

	return (
		<>
			<PageTitle post={aboutFeature}/>
			<AboutContent />
			<Box sx={{ backgroundColor: '#f7f7f7', pb: '4rem' }}>
				<Grid container justifyContent='center'>
					<Grid item xs={10} sm={8} md={6}>
						<Typography variant='h3' align='center' sx={{ my: 5, fontWeight: 'bold', color: '#333' }}>
							Ready to accelerate your racing dream? Join the FundRacer community today!
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default About;
