import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid , LinearProgress} from '@mui/material';
import { GridSize } from '@mui/material/Grid';
import { Campaign } from 'src/models';
import { Link } from 'react-router-dom';

type GRID_SIZES = 'auto' | true | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
interface SingleCardProps {
	post: Campaign;
	cardinraw: number;
}

export default function SingleCard(props: SingleCardProps) {
	const mdvalue = 4 / props.cardinraw;
	const post = props.post;

	return (
		<Grid item xs={10} md={Number(mdvalue) as GridSize}>
			<Card sx={{ display: 'flex', height: '110%', boxShadow: '2px 2px 8px rgba(255, 0, 0, 0.1)' }}>
				<CardActionArea component={Link} to={'/admin/viewcampaign/' + post.id} sx={{ height: '100%', display: 'flex', flexDirection: 'column', textDecoration: 'none', alignItems: 'flex-start'  }}>
				<CardMedia
					component='img'
					height='00'
					image={post.link_thumbnail}
					sx={{ objectFit: 'cover', height: '55%', flexShrink: 1 , position: 'relative', top: -33, left: 0 }}/>

				<CardContent sx={{ paddingLeft: 3, flexDirection: 'row', textAlign: 'left'}}>
						<Typography gutterBottom variant='h4' component='div' sx={{ color: '#00008B', fontWeight: 'bold', position: 'relative', top: -33 }}>
							{post.name}
						</Typography>
						<Typography variant='body2' color='text.secondary' sx={{ position: 'relative', top: -40 }}>
							{post.short_description}
						</Typography >
							<LinearProgress
								variant='determinate'
								value={post.donation_received ? Math.min((post.donation_received / post.fund_goal) * 100, 100) : 0}
								sx={{
									position: 'absolute',
									top: 310, 
									borderRadius: 100,
									width: '80%',
									height: 10,
								}}
							/>
							<Typography variant='body2' color='text.secondary' sx={{ position: 'relative', top: -10 , fontWeight: 'bold', color: '#000000'}}>
							$0 of ${post.fund_goal} recieved
							</Typography >
				</CardContent>
				</CardActionArea>
			</Card>
		</Grid>
	);
}
