import React from 'react';
import Admincopyright from './Admincopyright';

function Adminfooter(props: any) {
	return (
		<div>
			<Admincopyright />
		</div>
	);
}

export default Adminfooter;
