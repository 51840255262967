import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Sharebtn from './DropdownShareButton';
import { useNavigate } from 'react-router-dom';

type PageTitleProps = {
  post: {
    image?: string;
    title: string;
    qrimageurl?: string;
    bgText?: string;
    page?: string;
  };
};

export default function PageTitle(props: PageTitleProps) {
  const { post } = props;
  const navigate = useNavigate();

  console.log('post', post);

  return (
    <Paper
      sx={{
        position: 'relative',
        color: '#fff',
        mb: 4,
        backgroundColor: post.page === 'campaigns' ? '#79d1f8' : 'coral',
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          width: '100%',
        }}
      >
        <Grid container>
          <Grid
            item
            md={12}
            sx={{
              position: 'relative',
              height: post.page === 'campaigns' ? '100px' : '400px',
              width: '100%',
            }}
          >
            {post.page === 'campaigns' ? (
              <Typography component='h1' variant='h3' color='inherit' gutterBottom style={{ paddingLeft: '20px', paddingTop: '20px', color: 'white' }}>
                {post.title}
              </Typography>
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundImage: `url(${post.image})`,
                  width: '100%',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                {post.page !== 'about' ? (
                  <Box
                    sx={{
                      position: 'relative',
                      p: { xs: 3, md: 6 },
                      pr: { md: 0 },
                      backgroundColor: 'rgba(0,0,0,.3)',
                    }}
                  >
                    <Typography component='h1' variant='h3' color='inherit' gutterBottom>
                      {post.title}
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '140px',
                        left: '40px',
                        width: '400px',
                        color: 'white',
                        fontSize: '24px',
                        fontWeight: '600',
                      }}
                    >
                      {post.bgText}
                    </div>
                    <Button
                      variant='contained'
                      size='large'
                      style={{ position: 'absolute', bottom: '80px', left: '40px', borderRadius: '20px', background: '#fc7703' }}
                      onClick={() => navigate('/aboutMore')}
                    >
                      learn more
                    </Button>
                  </>
                )}
                {post.qrimageurl && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '40px',
                      right: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      width: '500px',
                      flexDirection: 'column',
                    }}
                  >
                    <img src={post.qrimageurl} alt='QR Code' style={{ maxWidth: '50%', height: 'auto' }} />
                    <Sharebtn />
                  </Box>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
