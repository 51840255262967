import React from 'react';

const SuccessPage = () => {
	return (
		<div>
			<h2>Donation Successfully Complete</h2>
			<h3>Thank You</h3>
		</div>
	);
};

export default SuccessPage;
