import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

function Copyright() {
	return (
		<Typography variant='body2' color='text.secondary' align='center'>
			{'Copyright © '}
			<Link color='inherit' href='https://mui.com/'>
				FundRacer
			</Link>
			{new Date().getFullYear()}
		</Typography>
	);
}

interface FooterProps {
	description: string;
	title: string;
}

export default function Footer(props: FooterProps) {
	const { description, title } = props;
	const logo = require('./racerXlogo.jpg');

	const facebookLink = 'https://www.facebook.com/profile.php?id=100092865421321&mibextid=LQQJ4d';
	const insLink ='https://instagram.com/fundracer.ins?igshid=NTc4MTIwNjQ2YQ==';
	const twitterLink = 'https://twitter.com/Fundracer_R';
	const youtubeLInk = 'https://youtube.com/@Fundracer';

	return (
		<Box sx={{ bgcolor: '#F2EDEB', py: 3, marginTop: 3 }}>
			<Container maxWidth='lg'>
				<Typography variant='h5' align='center' gutterBottom color="text.primary">
					<Box sx={{ mr: 1, align: 'center' }}>
						<img src={logo} alt='Logo' />
					</Box>
					{title}
				</Typography>
				<div style={{ display: 'flex', flexDirection: 'row', width: '300px', margin: '10px auto', alignItems:'center',justifyContent: 'center'}}>
					<Link href={facebookLink}>
						<FacebookIcon style={{ color: '#8b0000', margin: 5, width: 25, height: 20 }} />
					</Link>
					<Link href={facebookLink} style={{textDecoration:'none'}}>
						<span style={{ color: '#8b0000', marginRight: '10px'}} >Facebook</span>
					</Link>
					<Link href={insLink}>
						<InstagramIcon style={{ color: '#8b0000', margin: 5, width: 20, height: 20 }} />
					</Link>
					<Link href={insLink} style={{textDecoration:'none'}}>
						<span style={{ color: '#8b0000', marginRight: '10px'}} >Instagram</span>
					</Link>
					<Link href={twitterLink}>
						<TwitterIcon style={{ color: '#8b0000', margin: 5, width: 20, height: 20 }} />
					</Link>
					<Link href={twitterLink} style={{textDecoration:'none'}}>
						<span style={{ color: '#8b0000', marginRight: '10px'}} >Twitter</span>
					</Link>
					<Link href={youtubeLInk}>
						<YouTubeIcon style={{ color: '#8b0000', margin: '0 5px', width: 20, height: 20 }} />
					</Link>
					<Link href={youtubeLInk} style={{textDecoration:'none'}}>
						<span style={{ color: '#8b0000'}} >Youtube</span>
					</Link>
				</div>
				<Copyright />
			</Container>
		</Box>
	);
}
