import './AboutMore.css';
import Button from '@mui/material/Button';

const content = [
	{
		title: 'Step 1: Create a campaign name',
		content:
			'The name plays a role in attracting investors, so the name of the campaign is very.',
	},
	{
		title: 'Step 2: Fill in the short description and specific description',
		content:
			'Description allows investors to better understand your campaign.'
	},
	{
		title: 'Step 3: Determine the start time and end time',
		content:
			'Plan the entire process of the campaign well, so that investors have time to fully understand and consider.'
	},
	{
		title: 'Step 4: Fill Fund goal',
		content:
			'Investors will decide the amount of investment based on your Fund goal.'
	},
];

const mainContent = [
  {title:'Before submit', desc: 'You can also make a video and add the video link to the link for video box.'},
  {title:'', desc: 'You can also submit more files about your campaign. This allows investors to have a comprehensive understanding of all aspects of your campaign. Once everything is confirmed, you can submit. Wait for funds and use it to fulfill your dreams.'}
]

function AboutMore() {
	return (
		<div className='about-more-content'>
			{/* about more top content  */}
			<div className='about-more-top-title'>
			<div className='about-more-title'>learn how to create a campaign</div>
				{content.map((item, index) => {
					return <TopContent title={item.title} content={item.content} key={index} />;
				})}
			</div>
	
			<div className='about-main-content-container'>
			{
        mainContent.map((item,index)=>{
          return <MainContent title={item.title} desc={item.desc} key={index}/>
        })
      }
			</div>
		</div>
	);
}

const TopContent = (props) => {
	return (
		<div className='top-content-container'>
			<div className='top-content-title'>{props.title}</div>
			<div className='top-content-content'>{props.content}</div>
		</div>
	);
};

const MainContent = (props) => {
	return (
		<div className='main-content-container'>
			<div className='main-content-container-title'>{props.title}</div>
			<div className='main-content-container-desc'>
				{props.desc}
			</div>
			{/* <Button variant='contained' size='large' style={{ background: '#fc7703', borderRadius: '20px', margin: '20px' }} onClick={props.onClick}>
				{props.button}
			</Button> */}
		</div>
	);
};

export default AboutMore;
